import { useState, useEffect, useRef } from "react";
import Header from "./header";
import logoImg from "../assets/icons/logo.png";
import { BiLike, BiDislike, BiCopyAlt } from "react-icons/bi";
import { PiPaperPlaneRightBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { db, auth, onAuthStateChanged } from '../firebase';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { UserCircleIcon } from "@heroicons/react/solid";
import { HomeIcon } from "lucide-react";

const Chat = ({ user, signIn, selectedLanguage }) => {
    const [question, setQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [expandedCitations, setExpandedCitations] = useState({});

    const userCollectionRef = collection(db, "saved_chats");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!question.trim()) return;

        const newMessage = { user: question };
        setChatHistory(prev => [...prev, newMessage]);
        setQuestion('');
        setIsLoading(true);

        let loadingHasStopped = false; // Added to control loading state

        try {
            const response = await fetch(
                `https://athena.gaia-ml.com/api/chat-one/?query=${encodeURIComponent(question)}&selectedLanguage=${selectedLanguage}&stream=true`
            );

            if (!response.ok) throw new Error('Failed to fetch');

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';
            let aiResponse = '';
            let citations = [];

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');

                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.slice(6));
                            if (data.type === 'search_results') {
                                citations = data.data;
                            } else if (data.type === 'ai_response') {
                                aiResponse += data.data;
                                setChatHistory(prev => {
                                    const updated = [...prev];
                                    updated[updated.length - 1] = {
                                        ...updated[updated.length - 1],
                                        athena: aiResponse,
                                        citations
                                    };
                                    return updated;
                                });

                                if (!loadingHasStopped) {
                                    setIsLoading(false); // Stop loading indicator
                                    loadingHasStopped = true;
                                }
                            }
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                    }
                }
                buffer = lines[lines.length - 1];
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            if (!loadingHasStopped) {
                setIsLoading(false);
            }
        }
    };

    const toggleCitation = (messageIndex, citationNumber) => {
        setExpandedCitations(prev => ({
            ...prev,
            [messageIndex]: prev[messageIndex] === citationNumber ? null : citationNumber
        }));
    };

    const formatMessageWithCitations = (text, citations, messageIndex) => {
        if (!text) return null;
        const parts = text.split(/(\[\d+\])/g);
        return parts.map((part, index) => {
            const match = part.match(/\[(\d+)\]/);
            if (match) {
                const citationNumber = parseInt(match[1]);
                return (
                    <span key={index} className="inline-block">
                        <span
                            onClick={() => toggleCitation(messageIndex, citationNumber)}
                            className="inline-flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-blue-100 text-blue-800 font-medium hover:bg-blue-200"
                        >
                            {citationNumber}
                        </span>
                    </span>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            // Optional: Show a notification that the text was copied
            console.log('Text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleLogout = () => {
        auth.signOut();
    };

    return (
        <>
            <div className="flex sm:hidden">
                <Header />
            </div>
            <div className="flex flex-row h-screen">
                <aside className="w-0 sm:w-16 bg-gray-100 overflow-y-auto p-4 hidden sm:flex flex-col fixed h-full">
                    <nav className="flex-grow">
                        <ul className="flex flex-col items-center space-y-6 py-6">
                            <li>
                                <Link to="/" className="text-gray-500 hover:text-indigo-600 cursor-pointer">
                                    <HomeIcon className="w-6 h-6" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="flex flex-col items-center space-y-6 pb-6">
                        <div className="w-full flex items-center justify-center">
                            {user ? (
                                <div className="flex items-center">
                                    {user.photoURL ? (
                                        <img
                                            onClick={handleLogout}
                                            className="w-6 h-6 rounded-full cursor-pointer object-cover"
                                            src={user.photoURL}
                                            alt="User profile"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = '../assets/icons/users.svg';
                                            }}
                                        />
                                    ) : (
                                        <UserCircleIcon onClick={handleLogout} className="w-6 h-6 text-gray-500 cursor-pointer hover:text-indigo-600" />
                                    )}
                                </div>
                            ) : (
                                <UserCircleIcon onClick={signIn} className="w-6 h-6 text-gray-500 cursor-pointer hover:text-indigo-600" />
                            )}
                        </div>
                    </div>
                </aside>
                <div className="w-full sm:pl-16">
                    <div className="flex justify-center bg-gray-50 h-full">
                        <div className="w-full bg-white px-3 py-6 sm:px-0 h-full flex flex-col">
                            <div className="flex flex-col h-full">
                                <div className="flex-grow sm:px-32 flex flex-col">
                                    <div className="flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100% - 80px)' }}>
                                        <div className="flex flex-col p-2 sm:p-12 gap-4">
                                            {chatHistory.map((message, index) => (
                                                <div key={index}>
                                                    {message.user && (
                                                        <div className="flex mb-4">
                                                            <div className="w-12 flex-shrink-0">
                                                                {user?.photoURL ? (
                                                                    <img
                                                                        className="w-8 h-8 rounded-full object-cover"
                                                                        src={user.photoURL}
                                                                        alt="User profile"
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = '../assets/icons/users.svg';
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <UserCircleIcon className="w-8 h-8 text-gray-500" />
                                                                )}
                                                            </div>
                                                            <div className="flex-grow">
                                                                <p className="text-gray-800">{message.user}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {message.athena && (
                                                        <div className="flex mb-6">
                                                            <div className="w-12 flex-shrink-0">
                                                                <img src={logoImg} className="w-8 h-8 rounded-full object-cover" alt="AI" />
                                                            </div>
                                                            <div className="flex-grow">
                                                                <div className="text-gray-800 text-justify">
                                                                    {formatMessageWithCitations(message.athena, message.citations, index)}
                                                                </div>
                                                                {expandedCitations[index] != null && (() => {
                                                                    const citationNumber = expandedCitations[index];
                                                                    const citation = message.citations[citationNumber - 1];
                                                                    return (
                                                                        <div key={`${index}-${citationNumber}`} className="mt-2 mb-4 p-4 bg-gray-50 rounded-lg relative">
                                                                            {/* Close icon */}
                                                                            <button
                                                                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                                                                onClick={() => toggleCitation(index, citationNumber)}
                                                                            >
                                                                                &times;
                                                                            </button>
                                                                            {/* Citation content */}
                                                                            <a href={citation?.url} target="_blank" rel="noopener noreferrer" className="font-medium hover:underline hover:text-gray-600 text-gray-900">
                                                                                {citation.title}
                                                                            </a>
                                                                            {citation.summary && (
                                                                                <p className="mt-2 text-gray-600 text-sm">{citation.summary}</p>
                                                                            )}
                                                                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                                                                {citation.authors?.[0] && (
                                                                                    <span className="mr-4">{`${citation.authors[0]}${citation.authors.length > 1 ? " et al." : ""}`}</span>
                                                                                )}
                                                                                {citation.year && <span className="mr-4">{citation.year}</span>}
                                                                                {citation.citations && (
                                                                                    <span>{`${citation.citations} citations`}</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })()}
                                                                <div className="flex pt-2 space-x-2">
                                                                    <BiCopyAlt
                                                                        className="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
                                                                        onClick={() => handleCopy(message.athena)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {isLoading && (
                                                <div className="flex">
                                                    <div className="w-12 flex-shrink-0">
                                                        <img src={logoImg} className="w-8 h-8 rounded-full object-cover" alt="AI" />
                                                    </div>
                                                    <div className="flex-grow">
                                                        <div className="animate-pulse space-y-3">
                                                            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                                            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="bg-white sm:px-60 py-4 sticky bottom-0">
                                        <form onSubmit={user ? handleSubmit : signIn} className="flex p-2 rounded-lg border border-indigo-600">
                                            <input
                                                type="text"
                                                placeholder={selectedLanguage === 'es-ES' ? 'Empieza a escribir acá...' : 'Start typing here...'}
                                                value={question}
                                                onChange={(e) => setQuestion(e.target.value)}
                                                className="flex-grow bg-transparent rounded-3xl outline-none text-gray-700"
                                            />
                                            <button type="submit" className="rounded p-3 bg-indigo-600 hover:bg-indigo-500">
                                                <PiPaperPlaneRightBold className="w-5 text-white h-auto" />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            );
    };

export default Chat;
