import React, { useState, useEffect } from "react";
import { LuSparkles } from "react-icons/lu";
import { SearchIcon } from '@heroicons/react/solid';
import FAQComponent from "./components/faq"
import Footer from "./components/footer";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { TbPhysotherapist } from "react-icons/tb";
import { InformationCircleIcon, MenuIcon, UserCircleIcon } from "@heroicons/react/solid";
import { BeakerIcon, ChatAltIcon } from '@heroicons/react/solid';
import { Link } from "react-router-dom";
import UserInfoPopup from "./components/userinfo"
import { FaHeart } from "react-icons/fa";
import SolutionEngineFeatures from "./components/card";
import { PiCatBold, PiFlagBannerBold, PiMoneyBold } from "react-icons/pi";
import { MdOutlineBubbleChart } from "react-icons/md";

const Alert = ({ children }) => (
    <div className="bg-indigo-100 border-l-4 border-indigo-500 text-indigo-700 p-4 my-4 rounded">
        {children}
    </div>
);


const Home = ({ searchTerm, handleSearch, handleLogout, setSearchTerm, signIn, user, handleSelectLanguage, selectedLanguage, setSelectedLanguage, showModal, setShowModal }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleUserInfoSubmit = (info) => {
        setUserInfo(info);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleCatButtonClick = (e) => {
        e.preventDefault();
        const searchQuery = selectedLanguage === "es-ES"
            ? "¿Cómo se origina la conciencia en el cerebro?"
            : "How does consciousness arise in the brain?";
        setSearchTerm(searchQuery);
        setTimeout(() => {
            handleSearch(e, searchQuery);
        }, 0);
    };

    const two = (e) => {
        e.preventDefault();
        const searchQuery = selectedLanguage === "es-ES"
            ? "¿Cuál es el origen de la vida en la tierra?" : "What is the origin of life on Earth?";
        setSearchTerm(searchQuery);
        setTimeout(() => {
            handleSearch(e, searchQuery);
        }, 0);
    };

    const three = (e) => {
        e.preventDefault();
        const searchQuery = selectedLanguage === "es-ES"
            ? "¿Cómo funciona la evolución?" : "How does evolution work?";
        setSearchTerm(searchQuery);
        setTimeout(() => {
            handleSearch(e, searchQuery);
        }, 0);
    };

    const four = (e) => {
        e.preventDefault();
        const searchQuery = selectedLanguage === "es-ES"
            ? "¿Las transferencias de dinero reducen la pobreza?" : "Direct cash transfers reduce poverty?";
        setSearchTerm(searchQuery);
        setTimeout(() => {
            handleSearch(e, searchQuery);
        }, 0);
    };

    const searchQuestions = [
        { es: "¿Cómo se origina la conciencia en el cerebro?", en: "How does consciousness arise in the brain?", line: handleCatButtonClick },
        { es: "¿Cuál es el origen de la vida en la tierra?", en: "What is the origin of life on Earth?", line: two },
        { es: "¿Cómo funciona la evolución?", en: "How does evolution work?", line: three },
        { es: "¿Las transferencias de dinero reducen la pobreza?", en: "Do cash transfers reduce poverty?", line: four },
    ];


    return (
        <div>
            {showPopup && (
                <UserInfoPopup signIn={signIn} user={user} selectedLanguage={selectedLanguage} onClose={closePopup} onSubmit={handleUserInfoSubmit} />
            )}
            <div className="min-h-screen bg-white">
                <header className="p-4">
                    <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                        <div className="flex flex-col items-center sm:flex-row sm:items-center sm:space-x-2 mb-4 sm:mb-0">
                            <h1 className="text-xl font-bold text-gray-800 mb-2 sm:mb-0">VortiX</h1>
                            <button className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-medium flex items-center hover:bg-indigo-700 transition duration-300">
                                Experimental <BeakerIcon className="w-4 h-4 ml-2" />
                            </button>
                        </div>
                        <nav className="hidden sm:flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-6">
                            <Link to="/chat" className="text-gray-600 hover:text-gray-800 font-medium">Chat</Link>
                            {user ? (
                                <button onClick={handleLogout} className="text-white hover:bg-blue-700 bg-blue-600 rounded px-4 font-medium py-2 w-full sm:w-auto">{selectedLanguage === "es-ES" ? "Salir" : "Log out"}</button>
                            ) : (
                                <>
                                    <button onClick={signIn} className="text-white hover:bg-blue-700 bg-blue-600 font-medium rounded px-4 py-2 w-full sm:w-auto"> {selectedLanguage === "es-ES" ? "Acceder" : "Access"} </button>
                                </>
                            )}
                        </nav>
                    </div>
                </header>

                <main className="container mx-auto px-4 py-16 text-center">
                    <h1 className="text-5xl font-bold text-gray-900 mb-4">
                        {selectedLanguage === "es-ES" ? "Motor de búsqueda con IA para" : "AI Search Engine For"}
                    </h1>
                    <h2 className="text-5xl font-bold text-blue-600 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent pb-4">
                        {selectedLanguage === "es-ES" ? "Investigación científica" : "Scientific Research"}
                    </h2>
                    <p className="text-xl text-gray-600 mb-8">
                        {selectedLanguage === "es-ES" ? "Respuestas basadas en investigación científica y en datos" : "Answers based on data and scientific research"}
                    </p>

                    <form onSubmit={handleSearch} className="max-w-3xl mx-auto mb-8">
                        <div className="flex items-center bg-white rounded-full border-2 border-blue-500 overflow-hidden ">
                            <input
                                type="text"
                                placeholder={selectedLanguage === "es-ES" ? "Haz una pregunta..." : "Ask a research question..."}
                                className="flex-grow px-4 py-3 text-lg focus:outline-none"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button type="submit" className="text-blue-600 hover:text-blue-800 px-4 py-3 font-medium">
                                <SearchIcon onClick={handleSearch} className="h-6 cursor-pointer w-6" />
                            </button>
                        </div>
                    </form>

                    <div className="flex space-x-4 justify-center mb-8">
                    </div>

                    <p className="text-gray-600 mb-4"> {selectedLanguage === "es-ES" ? "¿Quieres un ejemplo? Haz clic en cualquiera de las opciones abajo" : "Want to try an example question? Click on any of the ones below:"} </p>
                    <div className="flex flex-wrap justify-center gap-4">
                        {searchQuestions.map((q, index) => (
                            <button
                                key={index}
                                onClick={q.line}
                                className="bg-blue-50 text-blue-700 px-4 py-2 rounded-md text-sm hover:bg-blue-100 transition duration-300"
                            >
                                {selectedLanguage === "es-ES" ? q.es : q.en}
                            </button>
                        ))}
                    </div>
                </main>
            </div>
            <div className="flex justify-center">
                <div className="w-11/12 grid-cols-1 grid grid-cols-1 sm:grid-cols-2">
                    <div className="flex py-20 items-center justify-center">
                        <img src="https://maresone.s3.us-east-1.amazonaws.com/demo-vortix.png" className="w-full rounded-lg h-auto" />
                    </div>
                    <div className="pb-12 sm:py-44 pl-4 pr-4 sm:pl-32 sm:pr-20">
                        <p className="text-gray-500 font-medium py-3">{selectedLanguage === "es-ES" ? "Análisis generado por IA" : "AI-generated analysis"}</p>
                        <p className="text-4xl font-medium text-gray-950 pt-5 pb-6">{selectedLanguage === "es-ES" ? "Resultados confiables de Semantic Scholar" : "Reliable search results from Semantic Scholar"}</p>
                        <p className="text-gray-500 font-medium py-8"> {selectedLanguage === "es-ES" ? "Resuelve tus dudas recurriendo a información académica, más de 220 millones de papers académicos" : "Get answers from over 220 million academic papers"} </p>
                        <div className="flex cursor-pointer mt-2">
                            <BsArrowUpRightCircleFill className="w-6 h-auto text-blue-800" />
                            <a target="_blank" href="https://gaia-ml.com/" className="flex underline ml-2 text-gray-500 cursor-pointer">{selectedLanguage === "es-ES" ? "¿Qué más estámos construyendo?" : "¿What else are we building?"} </a>
                        </div>
                    </div>
                </div>
            </div>
            <SolutionEngineFeatures selectedLanguage={selectedLanguage} />
            <FAQComponent selectedLanguage={selectedLanguage} />
            <Footer user={user} />
        </div>
    )
}

export default Home;