import {useState, useEffect} from "react";
import { ImQuotesLeft } from "react-icons/im";
import { IoIosDocument } from 'react-icons/io';
import { FaSpinner } from 'react-icons/fa';
import { doc, deleteDoc, collection } from 'firebase/firestore';
import { db } from "../firebase";
import { CheckCircleIcon, QuestionMarkCircleIcon, ClipboardIcon, XCircleIcon, NewspaperIcon, AcademicCapIcon, SparklesIcon, BookmarkIcon, UserCircleIcon, MenuAlt3Icon, ShareIcon, AdjustmentsIcon, InformationCircleIcon, ArrowDownIcon, XIcon, ChatAltIcon } from "@heroicons/react/solid";
import Header from "./header";
import Footer from "./footer";
import { RiDeleteBack2Fill } from "react-icons/ri";
import List from "./list"

const Saved = ({ selectedLanguage, savedPapers }) => {

    const sortedPapers = savedPapers.sort((a,b)=> b.timestamp - a.timestamp)

    const [expandedPapers, setExpandedPapers] = useState({});

    const toggleText = (index) => {
        setExpandedPapers(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });

    const formatCitationAPA = (paper) => {
        const authors = paper?.author || "";
        const year = paper?.year || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const pages = paper?.pages || "";

        return `${authors} (${year}). ${title}. ${journal}, ${volume}, ${pages}`;
    };

    const showCitationPopup = (paper) => {
        const citation = formatCitationAPA(paper);
        setCitationPopup({ show: true, citation });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const handleDelete = async (paper) => {
        const docRef = doc(db, "s3", paper.uid);
        try {
            await deleteDoc(docRef);
            alert("Document Deleted");
        } catch (error) {
            alert(error.message);
        }
    };


    return(
        <>
            {citationPopup.show && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50 ">
                        <div className="">
                            <div className='w-full flex bg-gray-200'>
                                <div className='w-1/6'>
                                </div>
                                <div className='w-4/6 flex justify-center py-3'>
                                    <p className='text-lg font-semibold text-gray-700'>{selectedLanguage === "es-ES" ? "Citar" : "Cite"}</p>
                                </div>
                                <div className='w-1/6 flex items-center justify-center'>
                                    <XCircleIcon onClick={closeCitationPopup} className='text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer' />
                                </div>
                            </div>
                            <div className='text-gray-600 rounded bg-gray-100 text-sm light p-6'>
                                {citationPopup.citation}
                                <ClipboardIcon
                                    className='mt-1 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600'
                                    onClick={() => navigator.clipboard.writeText(citationPopup.citation)}
                                    title="Copy to clipboard"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        <Header/>
            <div className="flex justify-center">
                <div className="w-full sm:w-4/5 py-8">

</div>
        </div>
            <div className="flex justify-center">
            <div className="w-full sm:w-4/5 pb-20">
            <div className="items-center w-full py-5">
            <div className="flex border-blue-800 pb-2 border-b mx-4 items-center mr-8 cursor-pointer">
                <div className="p-2 sm:ml-12">
                                {savedPapers.length? 
                                   <p className=" text-sm sm:text-lg font-semibold text-blue-800 ">{savedPapers.length}</p> :
                                    <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                                }
                            </div>
                <p className="font-semibold text-blue-800 text-sm sm:text-lg">{selectedLanguage === "es-ES" ? "Papers guardados" : "Saved papers"}</p>
            </div>
            </div>
            <div className="w-full">
                        {sortedPapers ? 
                        <>
                                {sortedPapers.map((paper, index) => (
                                    <div key={index} className='flex justify-center'>
                                        <div className='p-4 w-11/12 sm:w-5/6 rounded-xl bg-white my-6 shadow-2xl'>
                                            <div className='flex w-full justify-evenly'>
                                                <div className='w-1/12 flex items-center justify-center'>
                                                    <IoIosDocument className='w-4 text-gray-500 h-4' />
                                                </div>
                                                <a href={paper?.url} target='_blank' className='hover:underline w-4/5 py-2 flex items-center'>
                                                    <div className='w-full'>
                                                        <p className='text-gray-500 text-sm font-medium'>{paper?.title} ({paper?.year})</p>
                                                    </div>
                                                </a>
                                            </div>
                                            {paper && (
                                                <div className=''>
                                                    {(paper?.tldr || paper?.translatedAbstract) &&
                                                        <div className='flex w-full justify-center'>
                                                            <div className='w-full sm:w-11/12 my-2 rounded-xl p-2 bg-gray-100'>
                                                                <p style={expandedPapers[index] ? {} : {
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: '3',
                                                                    overflow: 'hidden'
                                                                }} className='text-gray-800'>
                                                                    {paper?.tldr || paper?.translatedAbstract}
                                                                </p>
                                                                <button onClick={() => toggleText(index)} className='text-blue-500'>
                                                                    {expandedPapers[index] ? selectedLanguage === "es-ES" ? "Ver menos" : "See less" : selectedLanguage === "es-ES" ? "Ver más" : "See more"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='flex justify-center pt-3 pb-6'>
                                                        <div className='w-11/12 flex'>
                                                            <div className='w-2/5 flex justify-center'><p className='text-gray-500 text-sm'>{paper?.journal?.name}</p></div>
                                                            <div className='w-1/3 flex justify-center px-2'><p className='text-gray-500 text-sm font-light italic'>
                                                                {paper?.author ? paper.author.split(',')[0] + " et al" : ''}
                                                            </p></div>
                                                            <div className='w-1/5 flex justify-center px-2'>
                                                                <p className='text-gray-500 text-sm'>
                                                                    {paper?.citationCount} {selectedLanguage === "es-ES" ? "Citas" : "Citations"}
                                                                </p>
                                                            </div>
                                                            <div className='w-1/6 flex justify-center'>
                                                                <p className='text-gray-500 text-sm'>{paper?.year}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="border-t px-6 flex border-gray-300">
                                                        <div className="sm:w-3/4 sm:mx-6 w-1/4 flex items-center py-5">
                                                        </div>
                                                        <div className="w-3/4 sm:w-1/4 flex py-5 justify-evenly">
                                                            <div className="flex items-center">
                                                                <button onClick={() => handleDelete(paper)} className="px-3 py-2 text-sm sm:text-base hover:bg-red-100 cursor-pointer font-semibold text-red-500 border-2 border-red-500 rounded ">{selectedLanguage === "es-ES" ? "Eliminar" : "Delete"}</button>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <button onClick={() => showCitationPopup(paper)} className="px-3 text-sm sm:text-base py-2 flex items-center hover:text-white rounded border-2 text-green-500 border-green-500 bg-white hover:bg-green-500">
                                                                    <ImQuotesLeft className="h-5 cursor-pointer w-auto" />
                                                                    <p className="pl-1 cursor-pointer font-semibold">{selectedLanguage === "es-ES" ? "Citar" : "Cite"}</p>
                                                            </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
            </> :
            <div className="flex my-12 justify-center">
                                <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
            </div> 
            }
            </div>
            </div>
            </div>
            <div className="hidden">
            </div>
            <Footer/>
        </>
    )
}

export default Saved;