import React from "react";
import { FaSpinner, FaGoogle, FaFacebook, FaTwitter, FaLinkedin, FaFire } from 'react-icons/fa';
import { BookmarkIcon } from "@heroicons/react/solid";

const List = ({ selectedLanguage, savedDocs, user, signIn }) => {
    const sortedPapers = savedDocs?.sort((a, b) => b.timestamp - a.timestamp);

    return (
        <div className="flex justify-center">
            <div className="w-full max-w-md">
                {/* Header */}
                <div className="items-center w-full py-5">
                    <div className="flex items-center border-b border-gray-300 pb-2 mx-4">
                        <BookmarkIcon className="w-6 h-6 text-blue-800 mr-2" />
                        <p className="text-blue-800">
                            {selectedLanguage === "es-ES" ? "Papers guardados" : "Saved Papers"}
                        </p>
                        {user && (
                            <span className="ml-auto text-sm text-gray-500">
                                {savedDocs?.length ?? 0}
                            </span>
                        )}
                    </div>
                </div>

                {/* Content */}
                <div className="w-full px-4">
                    {user ? (
                        <>
                            {sortedPapers ? (
                                sortedPapers.length > 0 ? (
                                    sortedPapers.map((paper, index) => (
                                        <div key={index} className="flex items-center py-2 border-b border-gray-200">
                                            <a
                                                href={paper?.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex-1 text-sm line-clamp-2 text-blue-600 hover:underline"
                                            >
                                                {paper?.title}
                                            </a>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center text-gray-500 py-4">
                                        {selectedLanguage === "es-ES"
                                            ? "No tiene papers guardados."
                                            : "You have no saved papers."}
                                    </p>
                                )
                            ) : (
                                <div className="flex justify-center my-12">
                                    <FaSpinner className="animate-spin w-6 h-6 text-gray-500" />
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex flex-col items-center py-8">
                            <FaFire className="w-12 h-12 text-red-600 mb-4" />
                            <p className="text-center text-gray-700 mb-4">
                                {selectedLanguage === "es-ES"
                                    ? "Inicie sesión para ver sus papers guardados"
                                    : "Sign in to see your saved papers"}
                            </p>
                            <button
                                onClick={signIn}
                                className="flex items-center bg-white text-gray-700 border border-gray-300 px-4 py-2 rounded shadow hover:bg-gray-100 transition-colors mb-4"
                            >
                                <FaGoogle className="w-5 h-5 mr-2 text-red-500" />
                                {selectedLanguage === "es-ES" ? "Iniciar sesión con Google" : "Sign in with Google"}
                            </button>
                            {/* Social Media Icons for sharing the website */}
                            <div className="flex space-x-4 text-gray-500 mt-4">
                                <a
                                    href="https://www.facebook.com/sharer/sharer.php?u=https://vortix.app"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share on Facebook"
                                >
                                    <FaFacebook className="w-6 h-6 hover:text-blue-600 transition-colors" />
                                </a>
                                <a
                                    href="https://twitter.com/intent/tweet?url=https://vortix.app&text=Check%20out%20Vortix!"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share on Twitter"
                                >
                                    <FaTwitter className="w-6 h-6 hover:text-blue-400 transition-colors" />
                                </a>
                                <a
                                    href="https://www.linkedin.com/shareArticle?mini=true&url=https://vortix.app&title=Check%20out%20Vortix!"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share on LinkedIn"
                                >
                                    <FaLinkedin className="w-6 h-6 hover:text-blue-700 transition-colors" />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default List;
