import { useState, useEffect } from "react"
import { FaFacebook, FaLinkedin, FaYoutube, FaTiktok, FaArrowAltCircleRight } from "react-icons/fa";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from "../firebase"
import {Link} from "react-router-dom"

const Footer = ({selectedLanguage, user}) => {

    const [s, setS] = useState("")

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        setS(savedLanguage)
    }, []);

    const emailRef = collection(db, "emails");

    const [correo, setCorreo] = useState("")

    const agregar = async (e) => {

        const uid = user?.uid
        await addDoc(emailRef, {
            uid: uid,
            correo: correo,
        });
        alert(selectedLanguage === "es-ES" ? "Suscrito" : "Subscribed");
        setCorreo("")
    }

    return (
        <>
            <div className="bg-gray-300">
                <div className="flex justify-center">
                    <div className="w-4/5 grid grid-cols-2 sm:grid-cols-3">
                        <div className="pt-8 pb-8">
                            <p className="text-lg font-medium text-gray-700 py-6">{s === "es-ES" ? "Acerca de" : "About us"}</p>
                            <a href="mailto:your@email.com?subject=Join%20Vortex" target="_blank" className="text-gray-700 cursor-pointer font-medium py-2">
                                <p className="py-2">{s === "es-ES" ? "Únete" : "Join us"}</p>
                            </a>
                            <Link to="/research" className=" text-gray-700 cursor-pointer font-medium"><p className="py-2">{s === "es-ES" ? "Investigación" : "Research"}</p></Link>
                            <Link to="/search" className=" text-gray-700 cursor-pointer font-medium"><p className="py-2">{s === "es-ES" ? "Búsqueda" : "Search"}</p></Link>

                        </div>
                        <div className="pt-8 pb-8">
                           
                        </div>
                        <div className="pt-8 hidden pb-8">
                            <div className="flex justify-evenly py-6 w-5/6">
                                <FaFacebook href="" target="_blank" className="w-6 h-6 text-gray-700 hover:text-gray-800 cursor-pointer" />
                                <FaLinkedin href="" target="_blank" className="w-6 h-6 text-gray-700 hover:text-gray-800 cursor-pointer" />
                                <FaYoutube href="" target="_blank" className="w-6 h-6 text-gray-700 hover:text-gray-800 cursor-pointer" />
                                <FaTiktok href="" target="_blank" className="w-6 h-6 text-gray-700 hover:text-gray-800 cursor-pointer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="w-4/5 grid grid-cols-1 sm:grid-cols-3">
                        <div className="">
                            <div className="">
                                <form 
                                type="submit"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    agregar()
                                }}
                                className="w-11/12 items-center rounded flex outline-none border-2 border-gray-200 bg-gray-200 ">
                                    <input 
                                    value={correo}
                                    onChange={(e) => { setCorreo(e.target.value) }} 
                                    className="w-5/6 px-2 outline-none py-1 bg-transparent border-none" 
                                    placeholder={selectedLanguage === "es-ES" ? "Correo electrónico" : "Email address"} />
                                    <FaArrowAltCircleRight onClick={() => {agregar()}} className="w-6 cursor-pointer hover:text-gray-700 h-6 text-gray-500 ml-1" />
                                </form>
                            </div>
                            <div className="pt-2 pb-20">
                                <p onClick={() => { agregar() }} className="text-gray-700 underline cursor-pointer hover:text-gray-800 font-medium">{selectedLanguage === "es-ES" ? "Suscríbete" : "Subscribe"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;