import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { BeakerIcon, ChatAltIcon } from '@heroicons/react/solid';

const Header = ({user, signIn}) => {


    const [selectedLanguage, setS] = useState("")

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        setS(savedLanguage)
    }, []);

    return (
        <div className="w-full flex">
            <Link to="/" className="flex items-center py-8 w-full md:w-1/3 xl:w-2/5 justify-center">
                <div className="flex items-center">
                    <h1 className="text-xl font-bold text-gray-800 mb-2 mr-2 sm:mb-0">VortiX</h1>
                    <button className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-medium flex items-center hover:bg-indigo-700 transition duration-300">
                        Experimental <BeakerIcon className="w-4 h-4 ml-2" />
                    </button>
                </div>
            </Link>
            <div className="md:w-2/3 xl:w-3/5 hidden sm:flex justify-center">
                <div className="flex my-8 sm:w-3/5 justify-evenly">
                </div>
            </div>
        </div>

    )
}

export default Header;